<template>
  <v-container fluid class="pa-0 ma-0">
    <v-toolbar color="#f0f0f0">
      <v-toolbar-title>
        <strong>{{ designPlanData.designPlanNumber }}</strong>
      </v-toolbar-title>
      <v-btn dark rounded color="#1565c0" @click="CloseTab()" class="ml-5">
        <v-icon>mdi-arrow-left</v-icon>Back
      </v-btn>

      <v-btn
        rounded
        @click="print()"
        color="success"
        class="ml-1"
        width="105px"
      >
        <v-icon color="#FAFAFA">mdi-printer</v-icon>Print
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="ml-1"
            dark
            rounded
            v-on="on"
            color="#1565c0"
            @click="addToFavorites(designPlanData.id)"
          >
            <v-icon color="yellow">
              {{ isDocFavorite ? " mdi-star" : " mdi-star-outline" }}
            </v-icon>
            Favorite
          </v-btn>
        </template>
        <span>Mark as favorite</span>
      </v-tooltip>

      <v-btn
        rounded
        @click="goToFeedback()"
        color="success"
        class="ml-1"
        width="135px"
      >
        <v-icon color="#FAFAFA">mdi-comment-quote</v-icon>Feedback
      </v-btn>

      <v-spacer></v-spacer>
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on }" class="mr-10">
          <v-icon color="#0792b0" large v-on="on">
            mdi-timeline-text
          </v-icon>
        </template>
        <span>View History</span>
      </v-tooltip> -->
    </v-toolbar>
    <v-card tile flat id="print">
      <v-alert
        dismissible
        prominent
        type="error"
        color="#fb8c00"
        dense
        border="left"
        class="mx-1 mt-1"
        elevation="2"
        v-if="pdfAlert"
      >
        <v-row align="center">
          <v-col class="grow">
            <strong>Note: </strong> If the Pdf file doesn't download.
            <span
              style="text-decoration: underline; font-weight: 700; cursor: pointer;"
              @click="getFile()"
              >Click here.</span
            >
            <v-progress-circular
              v-if="loadAgainLoading"
              indeterminate
              :size="15"
            ></v-progress-circular>
          </v-col>
          <!-- <v-col class="shrink">
          <v-btn @click="warningDialog = true">Click Here </v-btn>
        </v-col> -->
        </v-row>
      </v-alert>
      <a-row type="flex" justify="start">
        <a-col>
          <v-btn class="disabled" rounded text>
            <span class="ml-2"> メール アドレス: </span>
          </v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a class="ml-4" v-on="on" @click="Copy()"
                >sk-manual@ichijo.co.jp
              </a>
            </template>
            <span>Copy mail address to clipboard</span>
          </v-tooltip>

          <v-btn class="disabled" rounded text>
            <span class="ml-5">
              仕様マニュアル更新日：
              <span
                v-if="designPlanData.updatedDate == ''"
                style="text-transform: capitalize;"
                >Original Copy</span
              >
              <span v-else>{{ designPlanData.createdDate }}</span>
            </span>
          </v-btn>
          <!-- <span class="ml-2"> メール アドレス: </span>
          <span class="ml-2"> メール アドレス: </span> -->
        </a-col>
        <a-col :span="24"><hr /></a-col>
      </a-row>
    </v-card>
    <hr />
    <v-layout pt-4 pl-4>
      <p style="font-size: 20px; padding-top: 0; font-weight: bold;">
        件名:
      </p>

      <p
        style="font-size: 20px; padding-top: 0; font-weight: bold;"
        v-html="designPlanData.title"
      ></p>
    </v-layout>
    <hr />
    <v-card
      class="ma-1 pa-1"
      flat
      height="screenSize - 350"
      style="overflow-y: scroll"
    >
      <v-flex xs12>
        <div v-for="(item, i) in designPlanData.textContent" :key="i">
          <div v-html="item"></div>
        </div>
      </v-flex>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import fileView from "./SekkeiKikakuFileViewing";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      designPlanData: [],
      isDocFavorite: false,
      fileNamePdf: "",
      pdfAlert: false,
      excelAlert: false,
      warningDialog: false,
      loadAgainLoading: false,
      documentType: "",
      favoriteList: "",
    };
  },
  created() {
    this.loadDesignPlanData();
    this.getUserFavorites();
  },
  methods: {
    loadDesignPlanData() {
      let url = `${this.api}sekkeiKikaku/getSpecificKikaku/${this.$route.params.id}`;
      axios.get(url).then((res) => {
        console.log(res.data);
        this.designPlanData = res.data;
        console.log(this.designPlanData);
        this.designPlanData.textContent = this.designPlanData.textContent.map(
          (rec) => {
            return rec.replace(
              /http:\\\\10.11.1.59:2929\\uploads\\/g,
              "https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/"
            );
          }
        );
        if (res.data.fileName && res.data.isWithPDFAttachment) {
          // let url = `${process.env.VUE_APP_S3LOCATIONS}/DesignPlanning/`;
          this.pdfAlert = true;
          this.fileNamePdf = res.data.fileName;
          this.documentType = "pdf";
          // fileView.getFileSignedUrlViewing(this.fileNamePdf);
          // setTimeout(() => {
          //   window.open(`${url}${res.data.fileName}`);
          //   // window.open(`https://www.google.com`, '_blank')
          // }, 300);
        }
        if (res.data.isWithExcelAttachment) {
          this.excelAlert = true;
          this.documentType = "excel";
        }
      });
    },

    getUserFavorites() {
      this.favoriteList = "";
      this.isDocFavorite = false;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      let toGet = {
        userId: this.userInfo.id,
      };
      let url = `${this.api}sekkeiKikaku/getFavorites`;
      axios.post(url, toGet).then((res) => {
        this.favoriteList = res.data;
        for (let x in this.favoriteList) {
          if (
            this.favoriteList[x].designPlanNumber ==
            this.designPlanData.designPlanNumber
          ) {
            this.isDocFavorite = true;
            this.$forceUpdate();
            return;
          }
        }

        console.log("line2650", this.favoriteList);
      });
    },

    addToFavorites(val) {
      Swal.fire({
        title: !this.isDocFavorite
          ? "Add this to your favorites?"
          : "Delete this to your favorites",
        toast: true,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.addFavorites(val);
        }
      });
    },

    CloseTab() {
      window.close();
    },

    print() {
      console.log(this.designPlanData);
      window.open(`/KikakuPrint?id=${this.designPlanData.id}`, `_blank`);
    },
    copy() {},
    goToFeedback() {
      // window.open(`https://documentsearch.hrd-s.com/feedback`);
      // window.open(
      //   `https://documentsearch.hrd-s.com/feedback?id=${this.$route.params.id}&docNo=${this.designPlanData.documentType}&docType=${this.designPlanData.documentType}`
      // );
      window.open(
        `http://localhost:8081/feedback?id=${this.$route.params.id}&docNo=${this.designPlanData.designPlanNumber}&docType=${this.designPlanData.documentType}`
      );
    },
    addFavorites(val) {
      console.log(val);

      let data = this.designPlanData;

      console.log(this.isDocFavorite);
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      let toInsert = {
        id: data.id,
        designPlanNumber: data.designPlanNumber,
        title: data.title,
        userId: this.userInfo.id,
        isFavorite: !this.isDocFavorite,
      };
      // let favoriteCount = {
      //   id: data.id,
      //   FavoriteCount: data.FavoriteCount ? data.FavoriteCount + 1 : 1,
      //   favorite: favorite,
      // };
      // console.log(favoriteCount);
      let url = `${this.api}addFavorite/sekkeiKikaku`;
      let url2 = `${this.api}addFavoriteCount/sekkeiKikaku`;
      axios.post(url2, toInsert).then(() => {
        axios.post(url, toInsert).then((res) => {
          // console.log(res.data)
          if (res.data == "Add Favorite") {
            Swal.fire({
              icon: "success",
              title: `${data.designPlanNumber} added to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
            // this.loadDesignPlanData();
            this.getUserFavorites();
          } else {
            Swal.fire({
              icon: "error",
              title: `${data.designPlanNumber} deleted to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
            // this.loadDesignPlanData();
            this.getUserFavorites();
          }
        });
      });
    },
    getFile() {
      this.loadAgainLoading = true;
      if (this.documentType == "pdf") {
        fileView.getFileSignedUrlViewing(this.fileNamePdf);
        setTimeout(() => {
          this.loadAgainLoading = false;
        }, 3000);
      }
    },
    //
  },
};
</script>
