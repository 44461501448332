import Aws from "aws-sdk";
import config from "../config";
import tunnel from "tunnel";
// import Vue from "vue";
// import closeLoading from "./SekkeiNewTab.vue";

// const app = new Vue({
//   components: {
//     closeLoading,
//   },
//   template: "<closeLoading/>",
// }).$mount("#app");

var tunnelingAgent = tunnel.httpsOverHttp({
  proxy: {
    // Proxy settings
    host: "hrdproxy.hrd-s.com",
    port: 81,
    proxyAuth: "administrator:system",
  },
});

Aws.config.update({
  httpOptions: { agent: tunnelingAgent },
  region: "us-east-2",
  credentials: config,
});

var s3 = new Aws.S3({
  AccessKeyID: process.env.VUE_APP_ACCESS_KEY,
  SecretAccessKey: process.env.VUE_APP_SECRET_KEY,
  Region: "us-east-2",
  params: {
    bucket: "rulebook.files",
  },
});

export default {
  async getFileSignedUrlViewing(file) {
    let fileToGet = file;

    let downloadParams = {
      Bucket: "rulebook.files",
      Key: `DesignPlanning/${fileToGet}`,
      ResponseContentDisposition:
        "attachment; filename=" + encodeURIComponent(`${fileToGet}`, "UTF-8"),
    };

    // await s3.getSignedUrl("getObject", downloadParams, async (err, data) => {
    //   if (err) throw err;

    //   await fetch(data).then(
    //     async (r) =>
    //       await r.blob().then(async (res) => {
    //         if (res) {
    //           var file = new Blob([res], { type: "application/pdf" });
    //           var fileURL = URL.createObjectURL(file);
    //           //   window.open(fileURL, "_blank", "width=1000,height=1000");
    //           //   closeLoading.closeLoading();
    //           //   app.$children[0].closeLoading();

    //           window.open(fileURL, fileToGet);
    //         }
    //       })
    //   );
    // });
    await s3.getObject(downloadParams, function(err, pdfData) {
      if (err) {
        console.log(err, err.stack);
      } else {
        // Create a new Blob object with the PDF data
        var file = new Blob([pdfData.Body], { type: "application/pdf" });
        // Create a URL for the Blob object
        var fileURL = URL.createObjectURL(file);
        // Open the PDF file in a new tab
        window.open(fileURL, "_blank");
      }
    });
  },
  // async getFolderInS3(files) {
  //   const folderName = "myFolder";
  //   const localFolderName = "myLocalFolder";

  //   const params = {
  //     Bucket: "rulebook.files",
  //     Prefix: folderName + "/",
  //   };

  //   s3.listObjects(params, (err, data) => {
  //     if (err) {
  //       console.log(err);
  //       return;
  //     }
  //     data.Contents.forEach((obj) => {
  //       const key = obj.Key;
  //       const localKey = key.replace(folderName + "/", "");
  //       const localPath = `${localFolderName}/${localKey}`;
  //       const params = {
  //         Bucket: bucketName,
  //         Key: key,
  //       };
  //       s3.getObject(params, (err, data) => {
  //         if (err) {
  //           console.log(err);
  //           return;
  //         }
  //         const fileContent = data.Body;
  //         const fs = require("fs");
  //         fs.writeFileSync(localPath, fileContent);
  //       });
  //     });
  //   });
  // },
};
